<template>
  <div class="container-fluid">
    <div class="container min-height mt-5">
      <div class="row espace-prof top-forme justify-content-center text-left">
        <div class="col-md-10 bg-white shadow">
          <RubriquePro :niveau2="1" :niveau3="1" :niveau4="0" />
          <div class="row  descriptif-body p-3 dir-rtl">
            <div class="col-md-12">
              <div class="form-group">
                <label for="description">{{ $t('details_service.description') }} <sup>*</sup></label>
                <textarea style="width:100%;" v-model="form.description" id="description" name="description"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_en">{{ $t('details_service.description_en') }}</label>
                <textarea style="width:100%;" v-model="form.description_en" id="description_en" name="description_en"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_en.$error }" cols="30"
                  rows="7"></textarea>
                <div v-if="submitted && !$v.form.description_en.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'العربية' ? 'الوصف' : 'description' })
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="description_ar">{{ $t('details_service.description_ar') }}</label>
                <textarea style="width:100%;" v-model="form.description_ar" id="description_ar" name="description_ar"
                  class="form-control" :class="{ 'is-invalid': submitted && $v.form.description_ar.$error }" cols="30"
                  rows="7"></textarea>
                <!-- <div v-if="submitted && !$v.form.description_ar.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', { name: $i18n.locale === 'Français' ? 'description' : 'الوصف' })
                  }}
                </div> -->
              </div>
            </div>
            <div class="col-md-12">
              <b-table class="b-table mb-5" :items="getItems" :fields="fields" fixed>
                <!-- Custom template for the 'programme' field -->
                <template v-slot:cell(programme)="data">
                  <a v-if="data.item.programme"
                    :href="'https://msafar.ma/backend/public/storage/' + data.item.programme" target="_blank">
                    Afficher le programme
                  </a>
                </template>
                <template v-slot:cell(actions)="{ item }">
                  <span><button class="btn btn-sm btn-danger" @click="deleteItem(item)"><i
                        class="fa fa-times"></i></button></span>
                  <span><button class="btn btn-sm btn-warning" @click="selectItem(item)"><i
                        class="fa fa-edit"></i></button></span>
                </template>
              </b-table>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.activite') }}</label>
                    <textarea type="text" name="" id="" v-model="formTable.activite" class="form-control" placeholder=""
                      aria-describedby="helpId" required>
      </textarea>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.details') }}</label>
                    <input type="text" name="" id="" v-model="formTable.duree" class="form-control" placeholder=""
                      aria-describedby="helpId" required>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.prix_dh') }}</label>
                    <input type="text" name="" id="" v-model="formTable.prix_actv" class="form-control" placeholder=""
                      aria-describedby="helpId" required>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">{{ $t('forms.activite_pro.programme') }}</label>
                    <input type="file" id="programme" ref="programme" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4 pl-0">
                  <div class="form-group">
                    <label style="float: revert;" for="">&nbsp;</label>
                    <button v-if="!isEdit" class="form-control btn btn-primary bg-msafar-primary btn-add"
                      @click="addItem">{{
            $t('forms.generale.ajouter') }} </button>
                    <div class="btn-inline rtl-right" v-else>
                      <button class="form-control btn btn-primary bg-msafar-primary" @click="editItem">
                        {{ $t('forms.generale.enregistrer') }}</button>
                      <button class="form-control btn btn-secondary " @click="cancelEdit">
                        {{ $t('forms.generale.annuler') }} </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-md-12">
              <label for="programme">{{ $t('forms.activite_pro.programme') }}</label>
              <input type="file" @change="onFileChange" id="programme" name="programme" class="form-control" />

              <div v-if="form.programme">
                <a :href="'https://msafar.ma/backend/public/storage/' + form.programme" target="_blank">Afficher le programme </a>
              </div>

            </div> -->
            <div class="col-md-6 ml-4 mt-4">
              <h4>{{ $t('forms.activite_pro.prix_min_activite') }}</h4>
              <div class="form-group form-row mt-4">
                <label for="prix_min">{{ $t('forms.generale.prix_min') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_min" id="prix_min" name="prix_min" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_min.$error }" />
                <div v-if="submitted && !$v.form.prix_min.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
            name: $i18n.locale === 'Français' ? 'Prix Min' : 'السعر الأدنى'
          })
                  }}
                </div>
              </div>
              <div class="form-group form-row">
                <label for="prix_max">{{ $t('forms.generale.prix_max') }} <sup>*</sup></label>
                <input type="text" v-model="form.prix_max" id="prix_max" name="prix_max" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.form.prix_max.$error }" />
                <div v-if="submitted && !$v.form.prix_max.required" class="invalid-feedback">
                  {{ $t('forms.validations.required', {
            name: $i18n.locale === 'Français' ? 'Prix Max' : 'السعر الأقصى'
          })
                  }}
                </div>
              </div>
            </div>
            <!-- <div class="col-md-12 mt-3 pl-3 tabs-message-profil">
              <div>
                <b-tabs content-class="mt-3" justified>
                  <b-tab :title="$t('forms.generale.mes_messages')" active>
                    <div class="row justify-content-center">
                      <div class="col-md-11 message" v-for="message in messages" :key="message.id">
                        <p><strong>{{ message.username }} : </strong> {{ message.subject }}</p>
                        <p>{{ message.message }}</p>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab :title="$t('forms.generale.notification')">
                    <div class="row justify-content-center">
                      <div class="col-md-11 message" v-for="notification in commentaires" :key="notification.id">
                        <router-link :to="`/activites-touristiques/${form.slug}`">
                          <b-img blank blank-color="#01c203" class="mr-2" rounded="circle" width="12" height="12">
                          </b-img>
                          <span v-if="notification.user">{{ `${notification.user.nom} ${notification.user.prenom}`
                            }}</span> a ajouté un avis sur votre activité .
                        </router-link>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div> -->
            <div :class="[this.$i18n.locale === 'العربية' ? 'float-right' : '', 'col-md-12 mt-5 p-5']">
              <button type="submit"
                :class="[isDataAvailable ? 'btn-outline-msafar' : 'bg-msafar-primary text-white', 'btn rounded-pill pl-4 pr-5 pt-2 pb-2']"
                @click="validate">
                <i class="fas fa-check-circle mr-3"></i>
                <span v-if="isDataAvailable">{{ $t('forms.inscription.modifier') }}</span>
                <span v-else>{{ $t('forms.inscription.valider_pro') }}</span>
              </button>
              <div v-if="isDataAvailable" :class="this.$i18n.locale === 'العربية' ? 'float-left' : 'float-right'">
                <button type="button" @click="etapeSuivante()"
                  class="bg-msafar-primary btn text-white rounded-pill  pl-5 pr-5 pt-2 pb-2">
                  {{ $t('forms.inscription.etapes') }}
                  <span class="h1 color-msafar-primary flesh-profil" style="">
                    <b-icon-chevron-left v-if="this.$i18n.locale === 'العربية'"
                      style="margin-right: 40px;margin-bottom: 20px;"></b-icon-chevron-left>
                    <b-icon-chevron-right v-else></b-icon-chevron-right>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import User from "@/apis/User";
import Service from "@/apis/Services";
import http from "./../../http-common";
import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import { required } from 'vuelidate/lib/validators';

export default {
  name: "DescriptifComponent",
  components: { RubriquePro },
  computed: {
    getItems() {
      return this.items;
    },
    fields() {
      //https://github.com/bootstrap-vue/bootstrap-vue/issues/3409
      const lang = this.$i18n.locale
      if (lang === 'Français') {
        return [
          { key: "activite", label: "Activité" },
          { key: "details", label: "Détails" },
          { key: "prix_actv", label: "Prix" },
          { key: "programme", label: "Programme" },
          { key: "actions", label: "Actions" }
        ];
      } else if (lang === 'English') {
        return [
          { key: "activite", label: "Activity" },
          { key: "details", label: "Details" },
          { key: "prix_actv", label: "Price" },
          { key: "programme", label: "Program" },
          { key: "actions", label: "Actions" }
        ];
      } else {
        return [
          { key: "activite", label: "النشاط" },
          { key: "details", label: "تفاصيل" },
          { key: "prix_actv", label: "الثمن (DH)" },
          { key: "programme", label: "البرنامج" },
          { key: "actions", label: "إجراءات" }
        ];
      }
    },
  },
  data() {
    return {
      form: {
        description: "",
        description_ar: "",
        description_en: "",
        prix_min: "",
        prix_max: "",
        // programme: ""
      },
      columns: [
        "Activité",
        "Détails",
        "Prix à partir de",
        "Programme ",
        "Actions "
      ],
      persons: [],
      id_hotel: "",
      service: [],
      options: [],
      //fields: ["Type Chambre","Prix","Options","Actions"],
      // fields: [
      //   { key: "activite", label: "Activité" },
      //   { key: "duree", label: "Durée" },
      //   { key: "prix_actv", label: "Prix", },
      //   "Actions"
      // ],
      items: [
      ],
      hebergementServices: [],
      checked: [],
      tableItems: [],
      formTable: {
        activite: "",
        prix_actv: "",
        duree: "",
        programme: "",


      },
      optionsType: ['Chambre individuelle', 'Chambre double', 'Suite'],
      optionsListe: ['Demi-pension', 'pension complete', 'Demi-pension /pension complete'],
      isEdit: false,
      currentItem: [],
      messages: [],
      commentaires: [],
      submitted: false,
      isDataAvailable: false
    }
  },
  validations: {
    form: {
      description: { required },
      prix_min: { required },
      prix_max: { required },
    }
  },
  methods: {
    // onFileChange(event) {
    //   // Access the selected file from the event
    //   const file = event.target.files[0];

    //   // Create FormData object
    //   const formData = new FormData();
    //   formData.append('programme', file);

    //   // Send AJAX request to upload the file
    //   http.post(`/activites/image/${this.form.id}`, formData) // Remove the '/api/' prefix
    //     .then(response => {
    //       // Handle response if needed
    //       console.log(response.data);
    //     })
    //     .catch(error => {
    //       // Handle error if needed
    //       console.error(error);
    //       this.$toaster.error('Une erreur s\'est produite lors de l\'envoi du fichier');
    //     });
    // },

    etapeSuivante() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.isDataAvailable) {
        this.$router.push("/profil-pro/photos");
      } else {
        this.$toaster.info('Merci de valider le formulaire pour passer à l\'étape suivante');
      }
    },
    validate() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      Service.updateHebergement(this.form.id, this.user.type_service, this.form).then((response) => {
        this.form = response.data;
        this.isDataAvailable = true;
        this.$toaster.success('Votre espace professionnel a bien été modifié')
        this.$router.push("/profil-pro/photos");
      }).catch(error => {
        console.log(error);
      });
    },
    addItem() {
      this.formTable.id_activite = this.form.id;
      let formData = new FormData();
      formData.append('activite', this.formTable.activite);
      formData.append('prix_actv', this.formTable.prix_actv);
      formData.append('duree', this.formTable.duree);
      formData.append('id_activite', this.formTable.id_activite);
      formData.append('programme', this.$refs.programme.files[0]); // Get the file from the input
      http.post(`/type-activites`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Set content type for file upload
        }
      })
        .then((response) => {
          console.log(response)
          // this.items.push(response.data);
          this.getActivites(this.formTable.id_activite);
          this.$toaster.success('Activité a bien été ajouté');
          // Clear the form values
          this.$refs.programme.value = '';
          this.formTable = {
            activite: "",
            prix_actv: "",
            duree: "",
            programme: "",
          };
        })
        .catch(e => {
          console.log(e.data);
        });
    },


    cancelEdit() {
      this.currentItem = [];
      this.formTable.prix_actv = '';
      this.formTable.duree = '';
      this.formTable.activite = '';
      this.formTable.programme = '';
      this.isEdit = false;
    },
    selectItem(item) {
      this.currentItem = item;
      this.formTable.prix_actv = item.prix_actv;
      this.formTable.duree = item.duree;
      this.formTable.activite = item.activite;
      this.formTable.programme = item.programme;
      this.isEdit = true;
    },
    editItem() {
      const hasFile = this.$refs.programme.files.length > 0;

      // Prepare the form data based on file selection
      let formData;
      if (hasFile) {
        formData = new FormData();
        formData.append('activite', this.formTable.activite);
        formData.append('prix_actv', this.formTable.prix_actv);
        formData.append('duree', this.formTable.duree);
        formData.append('programme', this.$refs.programme.files[0]);
      } else {
        // If no file is selected, use the existing `this.formTable` object
        formData = this.formTable;
      }

      http.post(`/type-activites/update/${this.currentItem.id}`, formData, {
        headers: {
          'Content-Type': hasFile ? 'multipart/form-data' : 'application/json' // Set appropriate Content-Type based on file presence
        }
      })
        .then((response) => {
          // let objEdited = { ...response.data }; // Efficiently spread response data
          //this.items = this.items.map(obj => obj.id === this.currentItem.id ? objEdited : obj);
          console.log(response);
          this.getActivites(this.form.id);
          this.$toaster.success('Activité a bien été modifié');
          this.$refs.programme.value = ''; // Clear the input value after successful edit
          this.formTable = { // Reset form data regardless of file selection
            activite: "",
            prix_actv: "",
            duree: "",
            programme: "",
          };
        })
        .catch(e => {
          console.log(e.data);
        });
    }
    ,

    deleteItem(item) {
      http.delete(`/type-activites/${item.id}`)
        .then(() => {
          this.items = this.items.filter(obj => obj.id != item.id);
          this.$toaster.success('Activité a bien été supprimé')
        })
        .catch(e => {
          console.log(e.data);
        })
    },
    getUser() {
      User.auth().then((response) => {
        this.user = response.data;
        this.getHebergement();
      }).catch(error => {
        console.log(error);
      });
    },
    getHebergement() {
      console.log(this.user.id + ',' + this.user.type_service);
      Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
        this.form = response.data;
        this.items = response.data.type_activite.map(function (activite) {
          return { 'id': activite.id, 'activite': activite.activite, 'prix_actv': activite.prix_actv, 'duree': activite.duree, 'programme': activite.programme };
        });
        this.isDataAvailable = response.data.length !== 0 && response.data.description.length !== 0;
        // this.listeMessages(response.data.id);
        // this.getCommentaires(response.data.id);

      }).catch(error => {
        console.log(error);
      });
    },

    getActivites(id) {
      http.get(`/type-activites/${id}`)
        .then((response) => {
          // Assuming response.data contains the updated item data
          this.items = response.data;
          //this.items = this.items.map(item => item.id === id ? updatedItem : item);
        })
        .catch(e => {
          console.log(e.response.data);
        });
    },
    // getCommentaires(id) {
    //   http.get(`/commentaires/activite/${id}`).then(response => {
    //     this.commentaires = response.data;
    //   }).catch((errors) => {
    //     console.log(errors)
    //   });
    // },
    // listeMessages(id) {
    //   http.get(`/reservations/activite/${id}`)
    //     .then((response) => {
    //       this.messages = response.data
    //     })
    //     .catch(e => {
    //       console.log(e.data);
    //     })
    // },
  },
  mounted() {

  },
  created() {
    this.getUser();
  }
}
</script>

<style scoped>
.table thead th {
  border: 1px solid #bc4c58 !important;
  border-radius: 83px !important;
  text-align: center;
  color: #bc4c58;
  vertical-align: bottom;
}

.table thead th:last-child {
  border: 0px !important;
}

table {
  border-collapse: initial;
}



.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #ff9521;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #ff9521 !important;
}

.btn-danger {
  border-color: #dc3545 !important;
  padding: 4px 10px 4px 10px !important;
}

.btn-warning,
.btn-danger,
.btn-primary {
  border: 0;
  padding: 4px 10px 4px 10px !important;
  margin: 0px 5px;
  max-width: 90%;
}

.btn-add {
  margin-top: 30px;
}

.btn-inline {
  display: flex;
}


.message {
  background-color: #c6f0fc;
  border-radius: 25px;
  margin-bottom: 10px;
  padding: 15px;
}

.message p {
  padding: 2px;
  margin: 0px;
}

.message a {
  color: black;
}
</style>