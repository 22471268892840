<template>
    <div class="container-fluid">
        <div class="container min-height">
            <div class="row espace-voyageur top-forme justify-content-center mt-5">
                <div class="col-md-10 col-12 bg-white p-5 bg-login">
                    <div class="row">
                        <div class="container">
                            <h1 class=""><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                                    src="@/assets/images/img-bladi.png" />{{ $t('login_voyageur.login') }}</h1>
                            <!-- <div class="col-md-11 alert alert-danger" v-if="message.message">
                                <span>{{ message.message }}</span>
                            </div> -->
                            <div class="col-md-11">
                                <div class="form-group">
                                    <label for="email">{{ $t('forms.connexion.email') }}</label>
                                    <input type="email" v-model="form.email" class="form-control" id="email" />
                                    <span class="text-danger" v-if="errors.email">
                                        {{ errors.email[0] }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-11">
                                <div class="form-group">
                                    <label for="password">{{ $t('forms.connexion.mdp') }}</label>
                                    <div class="input-group ">
                                        <input v-if="showPassword" type="text" v-model="form.password"
                                            class="form-control" id="password" />
                                        <input v-else type="password" v-model="form.password" class="form-control"
                                            id="password">
                                        <span class="input-group-append">
                                            <button class="btn border-left-0 border password-eye" @click="toggleShow"
                                                type="button">
                                                <i class="fas"
                                                    :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                            </button>
                                        </span>
                                    </div>
                                    <span class="text-danger" v-if="errors.password">
                                        {{ errors.password[0] }}
                                        <br>
                                    </span>
                                    <label for="">
                                        <router-link to="/forgot-password" style=" padding: 0px 8px !important;"
                                            class="">
                                            {{ $t('forms.connexion.mdp_oublie') }}?</router-link>
                                    </label>
                                    <br>
                                    <label for="">
                                        <router-link to="/voyageur/dashbord" style=" padding: 0px 8px !important;"
                                            class="">{{ $t('forms.connexion.pas_dcompte') }}</router-link>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-11 mt-3">
                                <button type="submit" class="btn btn-success mr-2" @click.prevent="login">{{
                                        $t('forms.connexion.valider')
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import User from "@/apis/User";



export default {
    name: "formLogin",
    components: {},
    data() {
        return {
            showPassword: false,
            // password: null,
            form: {
                email: "",
                password: "",
                device_name: "browser",
            },
            errors: [],
            message: []
        };
    },

    methods: {
        login() {
            User.login(this.form)
                .then((response) => {
                    this.$root.$emit("login", true);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('role_id', response.data.user.role_id);

                    const role_id = response.data.user.role_id;

                    // Use the saved redirect path if available

                    const redirectPath = this.$route.query.redirect || '/';
                    // if (parseInt(role_id) === 2) {
                    //     this.$router.push({ name: "InformationCompteProfil" });
                    // } else {
                    //     this.$router.push({ name: "Profil" });
                    // }

                    // Check if the redirectPath is not the default '/'
                    if (redirectPath !== '/') {
                        const decodedPath = decodeURIComponent(redirectPath);
                        this.$router.replace(decodedPath).then(() => {
                            this.$router.go();
                        });

                    } else if (parseInt(role_id) === 2) {
                        this.$router.push({ name: "InformationCompteProfil" });
                        this.$router.go();

                    } else {
                        this.$router.push({ name: "Profil" });
                        this.$router.go();
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 401) {
                        this.message = error.response.data;
                        let message = error.response.data.message;
                        this.$toaster.error(message)
                    }
                });

        }
        ,

        // login() {
        //     User.login(this.form)
        //         .then((response) => {
        //             this.$root.$emit("login", true);
        //             localStorage.setItem('token', response.data.token);
        //             localStorage.setItem('id', response.data.user.id);
        //             localStorage.setItem('role_id', response.data.user.role_id);
        //             const role_id = response.data.user.role_id
        //             // const expirationTime = new Date().getTime() + 1 * 60 * 1000;
        //             // const token = {
        //             //     data: response.data.token,
        //             //     expires: expirationTime,
        //             // };
        //             // localStorage.setItem('token', JSON.stringify(token));
        //             // const id = {
        //             //     data: response.data.user.id,
        //             //     expires: expirationTime,
        //             // };
        //             // localStorage.setItem('id', JSON.stringify(id));
        //             // const role_id1 = {
        //             //     data: response.data.user.role_id,
        //             //     expires: expirationTime,
        //             // };
        //             // localStorage.setItem('role_id', JSON.stringify(role_id1));
        //             if (parseInt(role_id) === 2) {
        //                 this.$router.push({ name: "InformationCompteProfil" });
        //                 this.$router.go()
        //             } else {
        //                 this.$router.push({ name: "Profil" });
        //                 this.$router.go()
        //             }
        //         })
        //         .catch(error => {
        //             if (error.response.status === 422) {
        //                 this.errors = error.response.data.errors;
        //             } else if (error.response.status === 401) {
        //                 this.message = error.response.data;
        //                 let message = error.response.data.message;
        //                 this.$toaster.error(message)
        //             }
        //         });
        // },
        toggleShow() {
            this.showPassword = !this.showPassword;
        }
    },
    computed: {
        buttonLabel() {
            return (this.showPassword) ? "Hide" : "Show";
        }
    }
};
</script>
<style scoped>
.btn-success {
    background-color: white !important;
    background: white !important;
    color: #1e594d !important;
    border: 1px solid #1e594d !important;
    padding: 1px 40px;
    border-radius: 25px;
    font-family: GillSansMT;
    font-size: 20px;
}

.btn-success:hover {
    background-color: #008ec6 !important;
    background: #008ec6 !important;
    border: 1px solid #008ec6 !important;
    color: white !important;
}

.espace-voyageur a {
    font-family: GillSansMT;
    font-size: 14px !important;
    background: none !important;
    background: none !important;
    color: #008ec6 !important;
    padding: none !important;
    border-radius: none !important;
}

.bg-login {
    background: transparent url('@/assets/images/bg-connexion.png') no-repeat;
    background-size: cover;
    width: 450px;
    padding: 54px;
    margin: auto;
    height: 490px;
    /* margin-left: -17px;
    margin-right: -17px;
    margin-top: -17px;
    height: 414px; */
}

.form-control:focus,
.password-eye:focus {
    outline: 0 !important;
    border-color: initial;
    box-shadow: none;
}

.password-eye {
    background-color: #eeeeee;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: 1px solid #ced4da;
}

.password-eye i {
    color: #bbbbbb;
}

@media screen and (max-width: 700px) {
    .bg-login {
        background: transparent url('@/assets/images/fond-mobile-login.png') no-repeat;
        /* background-size: cover;
    width: 450px;
    padding: 54px;
    margin: auto;
    height: 490px;
     margin-left: -17px;
    margin-right: -17px;
    margin-top: -17px;
    height: 414px; */
        /*  background-size: cover;
    padding-top: 180px!important;
    padding-bottom: 140px!important;
    height: auto;*/
        background-size: contain;
    }
}
</style>