<template>
  <div class="container-fluid">
    <Rubrique></Rubrique>
    <div class="container min-height">
      <div class="row">
        <div class="col-md-12">
          <div class="row mt-5 justify-content-center">
            <div class="col-md-9"  id="scroll">
              <div class="row">
                <div class="col-md-12">
                  <h1 class=""><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                      src="@/assets/images/img-bladi.png" />{{ $t('rubrique.actualites') }}</h1>
                </div>
              </div>
              <div class="row search" >
                <div class="col-md-12">
                  <div class="form-group">
                    <input class="form-control form-control-sm " v-model="search" type="text"
                      style="font-family: 'Lato', cursive;padding-left: 10px" placeholder="Recherche par mots clés...">
                  </div>
                </div>
              </div>
              <div class="row actualites mt-4">
                <ListInfo :posts="lists" :slug="slug"></ListInfo>
                <!-- <div class="col-md-4 py-3" v-for="post of lists" v-bind:key="post.id">
                  <router-link v-bind:to="'/actualites/' + post.slug">
                     <div class="card" style="">
                      <img v-bind:src="BaseUrl + '/storage/' + post.image" class="card-img-top" />
                      <div class="card-body">
                        <h5 class="card-title" v-if="$i18n.locale === 'Français'">{{ post.titre ??  post.titre_ar }}</h5>
                        <h5 class="card-title" v-else>{{ post.titre_ar ??  post.titre}}</h5>
                        <div v-if="post.description_ar != null || post.description != null">
                          <p class="card-description" v-if="$i18n.locale === 'Français'">{{post.description ? (post.description.substring(0, 230) + ".." ): (post.description_ar.substring(0, 230) + ".." ) }}</p>
                          <p class="card-description" v-else>{{post.description_ar ? (post.description_ar.substring(0, 230) + ".." ): (post.description.substring(0, 230) + ".." ) }}</p>
                        </div>
                        <span class="float-right lire-plus">Lire plus <b-icon-arrow-right></b-icon-arrow-right></span>
                      </div>
                    </div>
                  </router-link>
                </div> -->
                <div class="col-md-12 ">
                  <!-- d-flex justify-content-center -->
                  <div class="overflow-auto d-flex justify-content-center pt-5">
                    <b-pagination v-if="lists.length >= 6" pills v-model="currentPage" :total-rows="totalRows"
                      :per-page="perPage" align="fill"></b-pagination>
                  </div>
                </div>
              </div>
            </div>
            <Sidebar></Sidebar>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import ListInfo from "@/components/ListInfo.vue";
// import $i18n from '../i18n.js';

export default {
  metaInfo() {
    const descriptionFr = "Blog spécialisé dans l'actualité touristique au Maroc, pour rester informé des dernières nouveautés.";
    const descriptionAr = "مدونة متخصصة في أخبار السياحة في المغرب.";
    const titleFr = "Actualités - Msafar.ma";
    const titleAr = "Msafar.ma - الأخبار";
    const image = "@/assets/images/logo.png"; // Update with your actual image URL

    return {
      title: this.$i18n.locale === "Français"  || this.$i18n.locale == "English" ? titleFr : titleAr,
      meta: [
        {
          name: "description",
          content: this.$i18n.locale === "Français"  || this.$i18n.locale == "English" ? descriptionFr : descriptionAr,
        },
        {
          property: "og:title",
          content: this.$i18n.locale === "Français"  || this.$i18n.locale == "English" ? titleFr : titleAr,
        },
        {
          property: "og:description",
          content: this.$i18n.locale === "Français"  || this.$i18n.locale == "English" ? descriptionFr : descriptionAr,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: window.location.href,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: this.$i18n.locale === "Français"  || this.$i18n.locale == "English" ? titleFr : titleAr,
        },
        {
          name: "twitter:description",
          content: this.$i18n.locale === "Français"  || this.$i18n.locale == "English" ? descriptionFr : descriptionAr,
        },
        {
          name: "twitter:image",
          content: image,
        },
        {
          name: "robots",
          content: "index, follow",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
      ],
    };
  },

  name: "ActualitesComponent",
  components: { Rubrique, Sidebar, ListInfo },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      posts: [],
      errors: [],
      //items:this.hotels,
      perPage: 6,
      currentPage: 1,
      search: '',
      slug: 'actualites'
    }
  },
  mounted() {
    http.get('/actualites')
      .then((response) => {
        //console.warn(response.data.data);
        this.posts = response.data
        /*.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
    )*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
  },
  computed: {
    lists() {
      const items = this.posts
      // Return just page of items needed
      return items.filter((item) => {
        return item.titre?.toLowerCase().includes(this.search.toLowerCase())
          || item.titre_ar?.toLowerCase().includes(this.search.toLowerCase())
          || item.seo_keywords?.toLowerCase().includes(this.search.toLowerCase())
          || item.description?.toLowerCase().includes(this.search.toLowerCase())
          || item.description_ar?.toLowerCase().includes(this.search.toLowerCase())
      })
        .slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        )
    },
    totalRows() {
      return this.posts.length
    }
  }
}

</script>

<style scoped></style>