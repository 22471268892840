<template>
  <div class="container-fluid">
    <Rubrique></Rubrique>
    <div class="container">
      <div class="row pt-5">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-12 "  id="scroll">
              <h1 class="mb-4"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
                  src="@/assets/images/img-bladi.png" />{{ $t('rubrique.gastronomie') }}</h1>
            </div>
          </div>
          <div class="row search mt-4">
            <div class="col-md-12">
              <div class="form-group">
                <input class="form-control form-control-sm " type="text" v-model="search"
                  style="font-family: 'Lato', cursive;padding-left: 10px" placeholder="Recherche par mots clés...">
              </div>
            </div>
          </div>
          <div class="row actualites mt-4">
            <div class="col-md-12 py-3" v-for="post of lists" v-bind:key="post.id">
              <router-link v-bind:to="'/gastronomies/' + ($i18n.locale === 'Français' || $i18n.locale === 'English' ? post.slug:(post.slug_ar||post.slug))">
                <div class="row">
                  <div class="col-md-4" style="">
                    <img v-bind:src="BaseUrl + '/storage/' + post.image" class="card-img-top shadow" />
                  </div>
                  <div class="col-md-8 shadow">
                    <div class="card-body pb-5">
                      <h5 class="card-title" v-if="$i18n.locale === 'Français'">{{ post.titre ??  post.titre_ar }}</h5>
                      <h5 class="card-title" v-else-if="$i18n.locale === 'English'">{{ post.titre_en ??  post.titre }}</h5>
                      <h5 class="card-title" v-else>{{ post.titre_ar ??  post.titre}}</h5>
                      <div v-if="post.description_ar != null || post.description != null">
                        <p class="card-description" v-if="$i18n.locale === 'Français'">{{post.description ? (post.description.substring(0, 230) + ".." ): (post.description_ar.substring(0, 230) + ".." ) }}</p>
                        <p class="card-description" v-else-if="$i18n.locale === 'English'">{{post.description_en ? (post.description_en.substring(0, 230) + ".." ): (post.description.substring(0, 230) + ".." ) }}</p>
                        <p class="card-description" v-else>{{post.description_ar ? (post.description_ar.substring(0, 230) + ".." ): (post.description.substring(0, 230) + ".." ) }}</p>
                      </div>
                      <span class="float-right lire-plus">{{ $t('details_service.lire_plus') }} <b-icon-arrow-right>
                        </b-icon-arrow-right></span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-md-12 ">
              <!-- d-flex justify-content-center -->
              <div class="overflow-auto d-flex justify-content-center pt-5">
                <b-pagination v-if="lists.length >= 6" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>

</template>

<script>


import http from "./../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import  $i18n  from '../i18n.js';



export default {
  metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return {
        title: 'Gastronomie',
        meta: [
          {
            name: "description",
            content: "Notre blog vous fait découvrir la richesse de la gastronomie marocaine et ses plats traditionnels pour une expérience culinaire authentique.",
          },
        ],
      };
    }
    else{
      return {
        title: 'فن الطهو',
        meta: [
          {
            name: "description",
            content: "تظهر مدونتنا لكم ثراء المأكولات المغربية وأطباقها التقليدية لتجربة طهو أصيلة.",
          },
        ],
      };
    }
    },
  name: "GastromoniesComponent",
  components: { Rubrique, Sidebar },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      posts: [],
      errors: [],
      //items:this.hotels,
      perPage: 6,
      currentPage: 1,
      search: ''
    }
  },
  mounted() {
    http.get('/gastronomies')
      .then((response) => {
        //console.warn(response.data.data);
        this.posts = response.data
        /*.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
    )*/
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
  },
  computed: {
    lists() {
      const items = this.posts.slice().sort(() => Math.random() - 0.5);
      // Return just page of items needed
      return items.filter((item) => { return item.titre?.toLowerCase().includes(this.search.toLowerCase())
        ||  item.titre_ar?.toLowerCase().includes(this.search.toLowerCase())
        ||  item.seo_keywords?.toLowerCase().includes(this.search.toLowerCase())
        ||  item.description?.toLowerCase().includes(this.search.toLowerCase())
        ||  item.description_ar?.toLowerCase().includes(this.search.toLowerCase())
      }).slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    totalRows() {
      return this.posts.length
    }
  }
}

</script>

<style scoped>

</style>