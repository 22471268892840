<template>
    <div>
        <div class="col-md-12 pb-5 text-center rubrique-pro">
            <div class="row dir-rtl">
                <div class="col-md-3 col-6 info-compte mb-2">
                    <div class="m-auto w-150px">
                        <router-link to="/professionnel/informations-compte" class="active">{{$t('espace_professionnel.infos_compte')}}
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3 col-6 coordonnees-activite mb-2">
                    <div class="m-auto w-150px">
                        <a>{{$t('espace_professionnel.crdns_activite')}}
                        </a>
                    </div>
                </div>
                <div class="col-md-3 col-6 descriptif mb-2">
                    <div class="m-auto w-150px">
                        <a>{{$t('espace_professionnel.descriptif')}}</a>
                    </div>
                </div>
                <div class="col-md-3 col-6 photos mb-2">
                    <div class="m-auto w-150px">
                        <a>{{$t('espace_professionnel.photos')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
a {
    font-size: 20px;
    line-height: 1.1;
    text-decoration: none !important;
}
</style>