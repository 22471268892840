import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/EspaceVoyageur/Login";
import VoyageurHome from "@/components/EspaceVoyageur/VoyageurHome";
import About from "@/components/About.vue";
import Voyage from "@/components/Voyages.vue";
import Partage from "@/components/Partage.vue";
import ServicesComponent from "@/components/Services.vue";
import PartageView from "@/components/PartageView.vue";
import MonCompte from "@/components/MonCompte.vue";
import Hotel from "@/components/Hebergements/Hotel.vue";
import HotelView from "@/components/Hebergements/HotelView.vue";
import Restaurants from "@/components/Hebergements/Restaurants.vue";
import RestaurantView from "@/components/Hebergements/RestaurantView.vue";
import MaisonsHotes from "@/components/Hebergements/MaisonsHotes.vue";
import MaisonHoteView from "@/components/Hebergements/MaisonHoteView.vue";
import Riads from "@/components/Hebergements/Riads.vue";
import RiadView from "@/components/Hebergements/RiadView.vue";
import ActivitesTouristiques from "@/components/Hebergements/ActivitesTouristiques.vue";
import ActiviteView from "@/components/Hebergements/ActiviteView.vue";
import GuidesTouristiques from "@/components/Hebergements/GuidesTouristiques.vue";
import GuideView from "@/components/Hebergements/GuideView.vue";
import VoyageurDashbord from "@/components/EspaceVoyageur/VoyageurDashbord.vue";
import Reservation from "@/components/Reservation.vue";
import Evenements from "@/components/Evenements";
import Actualites from "@/components/Actualites";
import ActualiteView from "@/components/ActualiteView";
import LieuxVisiter from "@/components/LieuxVisiter";
import LieuxVisiterView from "@/components/LieuxVisiterView";
import Gastronomies from "@/components/Gastronomie";
import GastronomiesView from "@/components/GastromoniesView";
import Temoignages from "@/components/Temoignages";
import Page from "@/components/Page";
import EvenementsView from "@/components/EvenementsView";
import CompteInfo from "@/components/MonProfil/CompteInfo";
import ChangerMdp from "@/components/MonProfil/ChangerMdp";
import Notification from "@/components/MonProfil/Notification";
import MesMessages from "@/components/MonProfil/MesMessages";
import MesPartages from "@/components/MonProfil/MesPartages";
import Mesfavoris from "@/components/MonProfil/Mesfavoris.vue";
import ProfilSidebar from "@/components/MonProfil/ProfilSidebar";
import InformationCompte from "@/components/EspaceProfessionnel/InformationCompte.vue";
import CoordonneesActivites from "@/components/EspaceProfessionnel/CoordonneesActivites.vue";
import ProfessionnelHome from "@/components/EspaceProfessionnel/ProfessionnelHome.vue";
import Descriptif from "@/components/EspaceProfessionnel/Descriptif.vue";
import Photos from "@/components/EspaceProfessionnel/Photos.vue";
import RiadPro from "@/components/EspaceProfessionnel/RiadPro.vue";
import MaisonPro from "@/components/EspaceProfessionnel/MaisonHotePro.vue";
import RestaurantPro from "@/components/EspaceProfessionnel/RestaurantPro.vue";
import GuidePro from "@/components/EspaceProfessionnel/GuidePro.vue";
import ActivitePro from "@/components/EspaceProfessionnel/ActivitePro.vue";
import HotelPro from "@/components/EspaceProfessionnel/HotelPro.vue";
import Felicitation from "@/components/EspaceProfessionnel/Felicitation.vue";
import MentionsLegales from "@/components/MentionsLegales.vue";
import InformationCompteProfil from "@/components/ProfilProfessionnel/InformationCompteProfil.vue";
import CoordonneesActivitesProfil from "@/components/ProfilProfessionnel/CoordonneesActivitesProfil.vue";
import PhotosProfil from "@/components/ProfilProfessionnel/PhotosProfil.vue";
import RiadProProfil from "@/components/ProfilProfessionnel/RiadProProfil.vue";
import MaisonHoteProProfil from "@/components/ProfilProfessionnel/MaisonHoteProProfil.vue";
import RestaurantProProfil from "@/components/ProfilProfessionnel/RestaurantProProfil.vue";
import GuideProProfil from "@/components/ProfilProfessionnel/GuideProProfil.vue";
import ActiviteProProfil from "@/components/ProfilProfessionnel/ActiviteProProfil.vue";
import HotelProProfil from "@/components/ProfilProfessionnel/HotelProProfil.vue";
import VerificationEmail from "@/components/VerificationEmail.vue";
import VerificationForm from "@/components/VerificationForm.vue";
import AppartHotels from "@/components/Hebergements/AppartHotels.vue";
import AppartHotelsView from "@/components/Hebergements/AppartHotelsView.vue";
import AppartHotelProfil from "@/components/ProfilProfessionnel/AppartHotelProfil.vue";
import MessagesNotificationsProfil from "@/components/ProfilProfessionnel/messagesNotificationsProfil.vue";
import ForgotPassword from '@/components/ForgotPassword.vue';
import ResetPassword from '@/components/ResetPassword.vue';

import PageNotFound from "@/components/404.vue";

import Contact from "@/components/Contact.vue";

import VueRouter from "vue-router";

Vue.use(Router);

// export default new Router({
//   mode: "history",
//   routes: [],
// });

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/mentions-legales",
        name: "MentionsLegales",
        component: MentionsLegales,
    },
    {
        path: "/verification-email",
        name: "VerificationForm",
        component: VerificationForm,
    },
    {
        path: "/verification-email-succes",
        name: "VerificationEmail",
        component: VerificationEmail,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    },
    {
        path: "/professionnel/felicitation",
        name: "Felicitation",
        component: Felicitation,
        meta: { authOnly: true },
    },
    {
        path: "/voyageur/espace-voyageur",
        name: "VoyageurHome",
        component: VoyageurHome,
    },
    {
        path: "/professionnel/espace-professionnel",
        name: "ProfessionnelHome",
        component: ProfessionnelHome,
    },
    {
        path: "/professionnel/coordonnees-activites",
        name: "CoordonneesActivites",
        component: CoordonneesActivites,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/descriptif",
        name: "Descriptif",
        component: Descriptif,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/hotel",
        name: "HotelPro",
        component: HotelPro,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/riad",
        name: "RiadPro",
        component: RiadPro,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/guide",
        name: "GuidePro",
        component: GuidePro,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/activite",
        name: "ActivitePro",
        component: ActivitePro,
        meta: { authOnly: true },

    },
    {
        path: "/professionnel/maison-hote",
        name: "MaisonPro",
        component: MaisonPro,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/restaurant",
        name: "RestaurantPro",
        component: RestaurantPro,
        meta: { authOnly: true },
    },
    {
        path: "/professionnel/photos",
        name: "Photos",
        component: Photos,
        meta: { authOnly: true },
    },
    /** Profil pro */
    {
        path: "/profil-pro/informations-compte",
        name: "InformationCompteProfil",
        component: InformationCompteProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/coordonnees-activites",
        name: "CoordonneesActivitesProfil",
        component: CoordonneesActivitesProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/message-et-notifications",
        name: "messagesNotificationsProfil",
        component: MessagesNotificationsProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/hotel",
        name: "HotelProProfil",
        component: HotelProProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/riad",
        name: "RiadProProfil",
        component: RiadProProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/guide",
        name: "GuideProProfil",
        component: GuideProProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/activite",
        name: "ActiviteProProfil",
        component: ActiviteProProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/maison-hote",
        name: "MaisonHoteProProfil",
        component: MaisonHoteProProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/restaurant",
        name: "RestaurantProProfil",
        component: RestaurantProProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/appart-hotels",
        name: "AppartHotelProfil",
        component: AppartHotelProfil,
        meta: { authOnly: true },
    },
    {
        path: "/profil-pro/photos",
        name: "PhotosProfil",
        component: PhotosProfil,
        meta: { authOnly: true },
    },
    /* End Profil pro */
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { guestOnly: true },
    },
    {
        path: "/register",
        name: "Register",
        component: VoyageurDashbord,
        meta: { guestOnly: true },
    },
    {
        path: "/voyages",
        name: "Voyage",
        component: Voyage,
        meta: { authOnly: true },
    },
    {
        path: "/page/:id",
        name: "page",
        component: Page,
    },
    {
        path: "/qui-sommes-nous",
        name: "About",
        component: About,
    },
    {
        path: "/partages",
        name: "Partage",
        component: Partage,
    },
    {
        path: "/services",
        name: "ServicesComponent",
        component: ServicesComponent,
    },
    {
        path: "/partages/:id",
        name: "PartageView",
        component: PartageView,
    },
    {
        path: "/mon-compte/felicitation",
        name: "MonCompte",
        component: MonCompte,
    },
    {
        path: "/hotels",
        name: "hotels",
        component: Hotel,
    },
    {
        path: "/hotels/:id",
        name: "HotelView",
        component: HotelView,
    },
    {
        path: "/restaurants",
        name: "restaurants",
        component: Restaurants,
    },
    {
        path: "/restaurants/:id",
        name: "RestaurantView",
        component: RestaurantView,
    },
    {
        path: "/maisons-hotes",
        name: "maisonsHotes",
        component: MaisonsHotes,
    },
    {
        path: "/maisons-hotes/:id",
        name: "MaisonHoteView",
        component: MaisonHoteView,
    },
    {
        path: "/riads",
        name: "riads",
        component: Riads,
    },
    {
        path: "/riads/:id",
        name: "RiadView",
        component: RiadView,
    },
    {
        path: "/apparthotels",
        name: "apparthotels",
        component: AppartHotels,
    },
    {
        path: "/apparthotels/:id",
        name: "AppartHotelsView",
        component: AppartHotelsView,
    },
    {
        path: "/activites-touristiques",
        name: "activites-touristiques",
        component: ActivitesTouristiques,
    },
    {
        path: "/activites-touristiques/:id",
        name: "ActiviteView",
        component: ActiviteView,
    },
    {
        path: "/professionnel/informations-compte",
        name: "InformationCompte",
        component: InformationCompte,
    },
    {
        // path: "/guides-touristiques",
        path: "/produit_artisanal",
        name: "produit_artisanal",
        component: GuidesTouristiques,
    },
    {
        path: "/produit_artisanal/:id",
        name: "produit_artisanal",
        component: GuideView,
    },
    {
        path: "/voyageur/dashbord",
        name: "VoyageurDashbord",
        component: VoyageurDashbord,
    },
    {
        path: "/reservation",
        name: "Reservation",
        component: Reservation,
    },
    {
        path: "/evenements",
        name: "Evenements",
        component: Evenements,
    },
    {
        path: "/evenements/:id",
        name: "EvenementsView",
        component: EvenementsView,
    },
    {
        path: "/actualites",
        name: "Actualites",
        component: Actualites,
    },
    {
        path: "/actualites/:id",
        name: "ActualiteView",
        component: ActualiteView,
    },
    {
        path: "/lieux-a-visiter",
        name: "LieuxVisiter",
        component: LieuxVisiter,
    },
    {
        path: "/lieux-a-visiter/:id",
        name: "LieuxVisiterView",
        component: LieuxVisiterView,
    },
    {
        path: "/gastronomies",
        name: "Gastronomies",
        component: Gastronomies,
    },
    {
        path: "/gastronomies/:id",
        name: "GastronomiesView",
        component: GastronomiesView,
    },
    {
        path: "/temoignages",
        name: "Temoignages",
        component: Temoignages,
    },

    {
        path: "/profil",
        name: "Profil",
        component: ProfilSidebar,
        meta: { authOnly: true, disableScroll: true },
        children: [{
                path: "mon-compte",
                name: "CompteInfo",
                component: CompteInfo,
            },
            {
                path: "notification",
                name: "Notification",
                component: Notification,
            },
            {
                path: "mes-partages",
                name: "MesPartages",
                component: MesPartages,
            },
            {
                path: "mes-favoris",
                name: "Mesfavoris",
                component: Mesfavoris,
            },
            {
                path: "changer-mot-de-passe",
                name: "ChangerMotDePasse",
                component: ChangerMdp,
            },
            {
                path: "mes-messages",
                name: "MesMessages",
                component: MesMessages,
            },
        ],
    },
    {
        path: "/users/:id",
        name: "CompteInfoUpdate",
        component: CompteInfo,
        meta: { authOnly: true },
    },
    { path: "*", component: PageNotFound }
];


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.matched.some((m) => m.meta.disableScroll)) return;

        const position = {
          x: 0,
          y: 0,
        };

        if (savedPosition) {
          position.x = savedPosition.x;
          position.y = savedPosition.y;
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            const element = document.getElementById('scroll');
            if (element) {
              resolve(
                element.scrollIntoView({ behavior: 'smooth' })
              );
            } else {
              resolve(position);
            }
          }, 400);
        });
      },
    // scrollBehavior() {
    //     document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    // }
});

function isLoggedIn() {
    return localStorage.getItem("token");
}
// function isLoggedInAsPro() {
//   return localStorage.getItem("role_id");
// }
// function isPro() {
//   return localStorage.getItem("pro");
// }
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.authOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        console.log(to);
        if (!isLoggedIn()) {
            next({
                path: "/voyageur/home",
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else if (to.matched.some((record) => record.meta.guestOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (isLoggedIn()) {
            next({
                path: "/",
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

export default router;