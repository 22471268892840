<template>
  <div class="container-fluid ">

    <Rubrique />
    <div class="container">
      <div class="row mt-5 pt-5">
        <div class="col-md-9 search" id="scroll">
          <h1 class="mb-5"><img style="margin-top: -15px;margin-right: 15px;width: 55px;"
              src="@/assets/images/img-bladi.png" />
            {{ $t('rubrique.restaurants') }}

          </h1>
          <div class="row dir-rtl">
            <div class="col-md-6">
              <div class="form-group">
                <input class="form-control form-control-sm " v-model="search" type="text"
                  :placeholder="$t('recherche.mot_cles')">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select class="form-control form-control-sm  ville" v-model="ville">
                  <option value="">{{ $t('recherche.villes') }}</option>
                  <option v-for="ville of villes" :key="ville.id" :value="ville.id">
                    {{  $i18n.locale === "Français"  || $i18n.locale == "English" ? ville.ville : ville.ville_ar }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row dir-rtl">
            <div class="col-md-6">
              <div class="form-group mt-4">
                <vue-range-slider v-model="value" :min="min" :max="max" :formatter="formatter"></vue-range-slider>
              </div>
            </div>

            <div class="col-md-6">
              <button class="btn btn-block btn-info rounded-pill" v-on:click="lists()">
                <b-icon-search></b-icon-search> {{ $t('recherche.rechercher') }}
              </button>
            </div>
          </div>
          <div class="row hotels restaurants">
            <!-- <div class="col-md-6 py-3" v-for="hotel of filteredItemsWithImages" v-bind:key="hotel.id">
              <router-link v-bind:to="'/restaurants/' + hotel.slug">
                <div class="card" style="">
                  <img v-bind:src="BaseUrl + '/storage/' + hotel.images[0].image" class="card-img-top responsive-img" />
                  <div class="card-body">
                    <h5 class="card-title" v-if="hotel.nom">{{ hotel.nom }}</h5>
                    <p class="card-text"><img src="@/assets/images/map-marker.png" class="map-marker" />
                      <span v-if="hotel.adresse">{{ hotel.adresse }}</span>
                    </p>
                    <p class="card-description" v-if="hotel.description"
                      v-html="hotel.description.substring(0, 230) + '..'"></p>
                    <p class="prix" v-if="hotel.prix">{{ $t('details_service.prix', [hotel.prix]) }}</p>
                    <div class="phone">
                      <div class="icon" v-if="hotel.whatsapp"><i class='fab fa-whatsapp fa-xl'></i> <span>{{ hotel.whatsapp
                      }}</span></div>
                      <div class="icon" v-else ><i class='fa  fa-phone fa-l'></i> <span>{{ hotel.tel ? hotel.tel : ''
                      }}</span></div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div> -->
            <Services_s :filteredItemsWithImages="lists" :slug="path" :BaseUrl="BaseUrl" />
            <Suggestion v-if="suggestion" :hotels="hotels" />
            <div class="col-md-12 ">
              <!-- d-flex justify-content-center -->
              <div class="overflow-auto d-flex justify-content-center pt-5">
                <b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill">
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
        <Sidebar></Sidebar>
      </div>
    </div>
  </div>
</template>

<script>


import http from "./../../http-common";
import Rubrique from "@/components/Outils/Rubrique";
import Sidebar from "@/components/Outils/Sidebar";
import Suggestion from "./CardComponents/Suggestion.vue";
import VueRangeSlider from 'vue-range-component'
import Services_s from "@/components/Hebergements/services.vue";
import  $i18n  from '../../i18n.js';



export default {
  metaInfo() {
    if( $i18n.locale == "Français"  || $i18n.locale == "English"){
      return {
        title: 'Restaurants',
        meta: [
          {
            name: "description",
            content: "Explorez les meilleurs restaurants marocains à Essaouira, Marrakech, Agadir et d'autres villes grâce à nos adresses d'exception.",
          },
        ],
      };
    }
    else{
      return {
        title: 'المطاعم',
        meta: [
          {
            name: "description",
            content: "اكتشفوا أفضل المطاعم المغربية في الصويرة، مراكش، أكادير ومدن أخرى.",
          },
        ],
      };
    }
    },
  name: "RestaurantsComponent",
  components: { Rubrique, Sidebar, Suggestion, VueRangeSlider,Services_s },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      hotels: [],
      filteredItems: [],
      villes: [],
      errors: [],
      suggestion: false,
      search: '',
      ville: '',
      prixMin: '',
      prixMax: '',
      //items:this.hotels,
      perPage: 6,
      currentPage: 1,
      value: [0, 3000],
      path:'restaurants',
      totalRows : 0,
    }
  },
  created() {
    this.min = 0
    this.max = 3000
    this.formatter = value => `${value} DH`;
    this.ville=this.$route.params.ville || '';
  },
  mounted() {
    // get liste des hotels
    http.get('/restaurants')
      .then((response) => {

        this.hotels = response.data
        this.filteredItems = response.data
        if (this.$route.query.ville)
          this.filteredItems = this.filteredItems
            .filter(post => { return parseInt(post.ville_id) == parseInt(this.$route.query.ville) })
        if (this.filteredItems.length == 0) {
          this.suggestion = true
        }
      })
      .catch(e => {
        this.errors.push(e)
      })
    // get liste des villes
    http.get('/villes')
      .then((response) => {
        this.villes = response.data
      })
      .catch(e => {
        this.errors.push(e)
      })
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
    },
    filteredList() {
      this.prixMin = this.value[0];
      this.prixMax = this.value[1];
      console.log(this.prixMin + " - " + this.prixMax);
      const items = this.hotels
        .filter(post => { return post.nom.toLowerCase().includes(this.search.toLowerCase()) })
        .filter(post => { return this.ville != '' ? post.ville.id == this.ville : true })
        .filter(post => {
          var price = false
          if (parseInt(this.prixMin) <= parseInt(post.prix_min) && parseInt(this.prixMax) >= parseInt(post.prix_min))
            price = true;
          else if (parseInt(this.prixMin) <= parseInt(post.prix_max) && parseInt(this.prixMax) >= parseInt(post.prix_max))
            price = true;
          return price;
        })
      this.filteredItemsWithImages = items;
      if (this.filteredItemsWithImages.length == 0) {
        this.suggestion = true;
      } else {
        this.suggestion = false;
      }

    },
    gettotalRows(list) {
      this.totalRows =  list.length
    }
  },
  computed: {
    filteredItemsWithImages() {
      let item = this.filteredItems.slice().sort(() => Math.random() - 0.5);
      return item.filter(hotel => hotel.firstimage && hotel.firstimage.image);
    },
    lists() {
      const items = this.filteredItemsWithImages
        .filter(post => { 
          var reslt1 = post.nom.toLowerCase().includes(this.search.toLowerCase())
          var reslt2 = post.description.toLowerCase().includes(this.search.toLowerCase())
          return reslt1 || reslt2
        })
        .filter(post => { return this.ville != '' ? post.ville.id == this.ville : true })
        .filter(post => {
          this.prixMin = this.value[0];
          this.prixMax = this.value[1];
          // console.log(this.prixMin+' '+this.prixMax);
          var price = false;
          if (this.prixMax != "")
            price = parseInt(post.prix_max) >= parseInt(this.prixMin) && parseInt(post.prix_max) <= parseInt(this.prixMax);
          else if (this.prixMin != "")
            price = parseInt(post.prix_max) >= parseInt(this.prixMin)
          else
            price = true;
          return price;
        })
        this.gettotalRows(items)
      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    }
    // ,
    // totalRows() {
    //   return this.filteredItemsWithImages.length
    // }


  }
}

</script>

<style scoped>
.vue-range-slider.slider-component .slider-tooltip-wrap .slider-tooltip {

  border: 1px solid #17a2b8 !important;
  background-color: #17a2b8 !important;
}
</style>