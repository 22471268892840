<template>
    <div class="col-md-12">
        <div class="col-md-12 line mb-5"></div>
        <!-- <b-icon class="float-right" v-b-modal.modal-add icon="plus-square" font-scale="2.7" variant="grey">
                </b-icon> -->
        <div class="row col-md-12 top-forme actualites margin-auto p-0">
            <template v-if="partages && partages.length > 0">
                            
            <div class="col-md-4 py-3" v-for="(partage, index) in partages" :key="index">
                <div class="card" style="">
                    <!-- <img src="http://placehold.jp/300x200.png" class="card-img-top" alt="...">-->
                    <img :src="BaseUrl + '/storage/' + partage.image" />
                    <div class="card-body">
                        <span class="card-description">{{ partage.created_at | formatDate }} </span>
                        <h5 class="card-title">{{ partage.titre }}</h5>
                        <router-link v-bind:to="'/partages/' + partage.slug">
                            <span class=" lire-plus">
                                <b-icon-arrow-right> </b-icon-arrow-right>Ouvrir l'emplacement
                            </span>
                        </router-link>
                        <div class="h2 mb-0">
                            <a href="#" v-b-modal.modal-update @click="edit(index)" class="mr-2">
                                <b-icon icon="pen" class="rounded-circle bg-warning p-2" variant="light">
                                </b-icon>
                            </a>
                            <a href="#" @click="showMsgBoxTwo(partage.id)">
                                <b-icon icon="trash" class="rounded-circle bg-danger p-2" variant="light">
                                </b-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            </template>
            <template v-else>                
                <div class="alert  w-100">
                    <h3>{{ $t("Partages.text") }}</h3>
                </div>
            </template>

        </div>
        <div>

            <b-modal ref="modal-update" id="modal-update" title="Modfier votre partage" size="lg">
                <div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 mb-md-0 mb-5">
                                <form id="contact-form" name="contact-form">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="name" class="">Votre titre</label>
                                                <input type="text" id="name" v-model="editInput.titre"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="message">Votre message</label>
                                                <textarea type="text" id="message" name="message" rows="4"
                                                    class="form-control md-textarea"
                                                    v-model="editInput.description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="form-group">
                                                <label for="email">Votre Image</label>
                                                <input type="file" id="image" @change="onFileChange"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <img v-if="preview" :src="preview" width="100px" height="100px" />
                                            <img v-else :src="BaseUrl + '/storage/' + editInput.image" alt=""
                                                height="100px" width="100px">
                                        </div>
                                    </div>
                                </form>
                                <div class="text-center  espace-voyageur ">
                                    <a class="btn text-white mt-2" v-on:click="updatePartage(editInput.id)">Modifier</a>
                                </div>
                                <div class="status"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
                <div slot="modal-footer">
                </div>
            </b-modal>
        </div>
    </div>

</template>

<script>
import Api from "@/apis/Api";

export default {
    name: "MesPartages",
    components: {
    },
    data() {
        return {
            BaseUrl: process.env.VUE_APP_API_URL,
            partages: [],
            preview: null,
            editInput: {
                id: '',
                user_id: '',
                titre: '',
                description: '',
                image: '',
            },
        }
    },
    methods: {
        onFileChange(event) {
            // this.editInput.image = event.target.files[0];
            // this.preview = URL.createObjectURL(this.editInput.image);
            this.form.image = event.target.files[0];
            this.preview = URL.createObjectURL(this.form.image);
        },
        edit(index) {
            this.editInput.titre = this.partages[index].titre;
            this.editInput.description = this.partages[index].description;
            this.editInput.image = this.partages[index].image;
            this.editInput.id = this.partages[index].id;
        },
        updatePartage() {
            let formData = new FormData;
            formData.set('titre', this.editInput.titre);
            formData.set('description', this.editInput.description);
            formData.set('image', this.editInput.image);
            Api().post(`/partages/${this.editInput.id}`, formData).then(() => {
                this.getUserPartages();
                this.$toaster.error('Votre post a été modifié.')
                this.$refs['modal-update'].hide();
            })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                });

        },
        showMsgBoxTwo(id) {
            this.boxTwo = ''
            this.$bvModal.msgBoxConfirm('Veuillez confirmer que vous souhaitez supprimer ce message.', {
                title: 'Veuillez confirmer',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Oui',
                cancelTitle: 'Non',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    this.boxTwo = value
                    if (this.boxTwo) {
                        Api().delete(`/partages/${id}`)
                        this.getUserPartages();
                        this.$toaster.error('Votre post a été supprimé.')
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                })
        },

        getUserPartages() {
            const id = localStorage.getItem("id");
            Api().get(`/partages/user/${id}`).then(response => {
                this.partages = response.data.partages;
                console.log(this.partages)

            }).catch((errors) => {
                console.log(errors)
            });
        },

    },
    created() {
        this.getUserPartages();
    },


}
</script>

<style scoped>

.line {

    border-bottom: 5px solid #cd001f;

}

.margin-auto{
    display: block;
    margin: auto;
}

</style>