<template>
  <div class="row hotels">
    <div class="col-md-6 py-3" v-for="item of filteredItemsWithImages" :key="item.id">
      <router-link :to="`/${item.url}/` + ($i18n.locale === 'Français' || $i18n.locale === 'English' ? item.slug : (item.slug_ar || item.slug))">
        <div class="card" style="">
          <img v-if="item.firstimage && item.firstimage.image" :src="BaseUrl + '/storage/' + item.firstimage.image"
            class="card-img-top responsive-img" />
          <img v-else src="@/assets/images/placeholder-image.jpg" class="card-img-top responsive-img" />
          <div class="card-body">
            <div v-if="item.nom || item.nom_ar">
              <h5 class="card-title" v-if="$i18n.locale === 'Français'">{{ item.nom ?? item.nom_ar }}</h5>
              <h5 class="card-title" v-else-if="$i18n.locale === 'English'">{{ item.nom_en ?? item.nom }}</h5>
              <h5 class="card-title" v-else>{{ item.nom_ar ?? item.nom }}</h5>
            </div>
            <p class="card-text">
              <img src="@/assets/images/map-marker.png" class="map-marker" />
              <span v-if="item.adresse">
                <span v-if="$i18n.locale === 'Français'">
                  {{ item.adresse }} {{ item.ville ? ('- ' + item.ville.ville) : '' }}
                </span>
                <span v-if="$i18n.locale === 'English'">
                  {{ item.adresse }} {{ item.ville ? ('- ' + item.ville.ville) : '' }}
                </span>
                <span v-else>
                  {{ item.adresse_ar || item.adresse }} {{ item.ville ? ('- ' + item.ville.ville_ar) : '' }}
                </span>
              </span>
            </p>
            <!-- <p class="card-description" v-if="item.description"
                v-html="item.description.substring(0, 230) + '...'"></p> -->
            <div v-if="item.description_ar != null || item.description != null">
              <p class="card-description" v-if="$i18n.locale === 'Français'">{{ item.description ?
                (item.description.substring(0, 230) + "..") : (item.description_ar.substring(0, 230) + "..") }}</p>
              <p class="card-description" v-else-if="$i18n.locale === 'English'">{{ item.description_en ?
                (item.description_en.substring(0, 230) + "..") : (item.description.substring(0, 230) + "..") }}</p>
              <p class="card-description" v-else>{{ item.description_ar ? (item.description_ar.substring(0, 230) + "..") :
                (item.description.substring(0, 230) + "..") }}</p>
            </div>
            <p class="prix" v-if="item.prix">{{ $t('details_service.prix', [item.prix]) }}</p>
            <div class="phone">
              <div class="icon" v-if="item.whatsapp">
                <i class="fab fa-whatsapp fa-xl"></i>
                <span> {{ item.whatsapp }}</span>
              </div>
              <div class="icon" v-else>
                <i class="fa fa-phone fa-l"></i>
                <span> {{ item.tel ? item.tel : '' }}</span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services_s",
  props: {
    filteredItemsWithImages: Array,
    slug: String,
    BaseUrl: String
  },
};
</script>

<style>
/* Add your CSS styles here */
</style>
