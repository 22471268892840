<template>
    <div>
        <div class="col-md-12 pb-5 text-center rubrique-pro">
            <div class="row dir-rtl" style="justify-content: space-evenly;">
                <div class="col-md-2 col-6 info-compte mb-2">
                    <div class="m-auto w-150px">
                        <router-link :to="{ name: 'InformationCompteProfil' }"
                            class="active">{{ $t('espace_professionnel.infos_compte') }}</router-link>
                    </div>
                </div>
                <div class="col-md-2 col-6 coordonnees-activite mb-2">
                    <div class="m-auto w-150px">
                        <router-link v-if="this.user.nom != '' && this.user.nom != null"
                            :to="{ name: 'CoordonneesActivitesProfil' }"
                            :class="[niveau2 == 1 ? 'active' : '']">{{ $t('espace_professionnel.crdns_activite') }}
                            <!-- :disabled="niveau2 == 1" :event="niveau2 == 1 ? 'click' : ''" :class="[niveau2 == 1 ? 'active' : '']" -->
                        </router-link>
                        <p v-else @click="showmessage('niveau2')" :class="[niveau2 == 1 ? 'active' : '']">
                            {{ $t('espace_professionnel.descriptif') }}</p>
                    </div>
                </div>
                <div class="col-md-2 col-6 descriptif mb-2">
                    <div class="m-auto w-150px">
                        <router-link v-if="this.user.nom != '' && this.user.nom != null" :to="{ name: linkToService }"
                            :class="[niveau3 == 1 ? 'active' : '']">{{ $t('espace_professionnel.descriptif') }}</router-link>
                        <p v-else @click="showmessage('niveau3')" :class="[niveau3 == 1 ? 'active' : '']">
                            {{ $t('espace_professionnel.descriptif') }}</p>
                        <!-- :disabled="niveau3 == 1" :event="niveau3 == 1 ? 'click' : ''"  :class="[niveau3 == 1 ? 'active' : '']" -->
                    </div>
                </div>
                <div class="col-md-2 col-6 photos mb-2">
                    <div class="m-auto w-150px">
                        <router-link v-if="this.form.description != null && this.form.description != ''"
                            :to="{ name: 'PhotosProfil' }"
                            :class="[niveau4 == 1 ? 'active' : '']">{{ $t('espace_professionnel.photos') }}</router-link>
                        <p v-else @click="showmessage('niveau4')" :class="[niveau4 == 1 ? 'active' : '']">
                            {{ $t('espace_professionnel.photos') }}</p>
                        <!-- :disabled="niveau4 == 1" :event="niveau4 == 1 ? 'click' : ''" :class="[niveau4 == 1 ? 'active' :' ']" -->
                    </div>
                </div>
                <div class="col-md-2 col-6 photos mb-2">
                    <div class="m-auto w-150px">
                        <router-link v-if="this.form.images && this.form.images.length > 0"
                            :to="{ name: 'messagesNotificationsProfil' }"
                            :class="[niveau5 == 1 ? 'active' : '']">{{ $t('espace_professionnel.msg_not') }}</router-link>
                        <p v-else @click="showmessage('niveau5')" :class="[niveau5 == 1 ? 'active' : '']">
                            {{ $t('espace_professionnel.msg_not') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import User from "@/apis/User";
import Service from "@/apis/Services";
export default {
    props: {
        /* niveau1:Number,*/
        niveau2: Number,
        niveau3: Number,
        niveau4: Number,
        niveau5: Number,
        level: Boolean,
    },
    data() {
        return {
            linkToService: "",
            user: [],
            form: [],
        }
    },
    mounted() {
        this.getUser();
        // this.$props.niveau2 = 1;
        // this.$props.niveau3 = 1;
        // this.$props.niveau4 = 1;
    },
    methods: {

        getUser() {
            User.auth().then((response) => {
                console.log(response.data);
                this.user = response.data;
                this.getHebergement(this.user.id, this.user.type_service)
                var typeService = response.data.type_service;
                console.log(typeService)
                if (typeService == 'hotel')
                    this.linkToService = 'HotelProProfil';
                else if (typeService == 'riad')
                    this.linkToService = 'RiadProProfil';
                else if (typeService == 'maison-hote')
                    this.linkToService = 'MaisonHoteProProfil';
                else if (typeService == 'restaurant')
                    this.linkToService = 'RestaurantProProfil';
                else if (typeService == 'activite')
                    this.linkToService = 'ActiviteProProfil';
                else if (typeService == 'guide')
                    this.linkToService = 'GuideProProfil';
                else if (typeService == 'appart-hotels')
                    this.linkToService = 'AppartHotelProfil';

            }).catch(error => {
                console.log(error);
                //$2y$10$o54zqJdQtHtdmM8d4X0KhOBBtSy4M.N9kSRV06RKoI0GH6jn4yILC
            });
        },
        getHebergement(id, type) {
            Service.getHeberegement(id, type).then((response) => {
                this.form = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        showmessage(niveau) {
            if (niveau == "niveau2") {
                this.$toaster.error('Merci de valider les informations du compte avant de passer aux coordonnées de l’activité')
            }
            if (niveau == "niveau3") {
                this.$toaster.error('Merci de valider les coordonnées de l’activité avant de passer au descriptif')
            }
            if (niveau == "niveau4") {
                this.$toaster.error('Merci de valider le descriptif avant de passer aux photos')
            }
            if (niveau == "niveau5") {
                this.$toaster.error('Merci de valider les photos avant de passer aux messages et notifications')
            }
        }
    },
}
</script>

<style scoped>
a {
    font-size: 20px;
    line-height: 1.1;
    text-decoration: none !important;
}

.rubrique-pro p {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid;
    float: left;
    border-radius: 98px;
    height: 150px;
    width: 150px;
    padding: 20px;
    color: #3ca2c6 !important;
    cursor: pointer;
    font-size: 20px;
}
</style>