<template>
    <div>
        <!-- <h4 class="card-title">{{ hotel.nom }}</h4> -->
        <div v-if="hotel.nom || hotel.nom_ar">
            <h1 class="invisible" v-if="$i18n.locale === 'Français'">{{ (hotel.nom + ' ' + (hotel.ville ? hotel.ville.ville : '')) || (hotel.nom_ar + ' ' + (hotel.ville ? hotel.ville.ville_ar : '')) }}</h1>
            <h1 class="invisible" v-else-if="$i18n.locale === 'English'">{{ (hotel.nom_en + ' ' + (hotel.ville ? hotel.ville.ville : '')) || (hotel.nom + ' ' + (hotel.ville ? hotel.ville.ville : '')) }}</h1>
            <h1 class="invisible" v-else>{{ (hotel.nom_ar + ' ' + (hotel.ville ? hotel.ville.ville_ar : '')) || (hotel.nom + ' ' + (hotel.ville ? hotel.ville.ville : '')) }}</h1>
            <h2 class="card-title" v-if="$i18n.locale === 'Français'">{{ hotel.nom ?? hotel.nom_ar}}</h2>
            <h2 class="card-title" v-else-if="$i18n.locale === 'English'">{{ hotel.nom_en ?? hotel.nom}}</h2>
            <h2 class="card-title" v-else>{{ hotel.nom_ar ?? hotel.nom}}</h2>

        </div>
        <div v-if="totalEtoiles">
            <p><img v-for="i in parseInt(totalEtoiles)" :key="i" src="@/assets/images/star.png" class="map-marker" /></p>
        </div>
        <p class="card-text"><img src="@/assets/images/map-marker.png" class="map-marker" /> 
        <!-- {{ hotel.adresse}} - <span v-if="hotel.ville">{{ hotel.ville.ville }}</span> -->
        <span v-if="$i18n.locale === 'Français'">
        {{ hotel.adresse }} {{hotel.ville ? ('- '+hotel.ville.ville):'' }}
        </span>
        <span v-else-if="$i18n.locale === 'English'">
        {{ hotel.adresse }} {{hotel.ville ? ('- '+hotel.ville.ville):'' }}
        </span>
        <span v-else>
        {{ hotel.adresse_ar || hotel.adresse}} {{hotel.ville ? ('- '+hotel.ville.ville_ar):'' }}
        </span>
        </p>
        <!-- <h5 style="color:#0172ae;font-size: 25px">{{ $t('details_service.description') }}</h5> -->
        <!-- <p class="card-description" v-html="hotel.description" style="font-size: 18px"></p> -->
        <div v-if="hotel.description_ar != null || hotel.description != null">
            <p class="card-description" style="font-size: 18px" v-if="$i18n.locale === 'Français'">{{hotel.description ? (hotel.description ): (hotel.description_ar ) }}</p>
            <p class="card-description" style="font-size: 18px" v-else-if="$i18n.locale === 'English'">{{hotel.description_en ? (hotel.description_en ): (hotel.description ) }}</p>
            <p class="card-description" style="font-size: 18px" v-else>{{hotel.description_ar ? (hotel.description_ar ): (hotel.description ) }}</p>
            <!-- <p class="card-description" style="font-size: 18px" v-if="$i18n.locale === 'Français'">{{hotel.description ? (hotel.description.substring(0, 230) + ".." ): (hotel.description_ar.substring(0, 230) + ".." ) }}</p>
            <p class="card-description" style="font-size: 18px" v-else>{{hotel.description_ar ? (hotel.description_ar.substring(0, 230) + ".." ): (hotel.description.substring(0, 230) + ".." ) }}</p> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardInfo',
    props: {
        totalEtoiles: [],
        hotel: [],
    }

}
</script>
<style scoped>
.card-title{
    text-transform: capitalize;
}
.invisible{
font-size: 1px;
}
</style>