<template>
  <div class="container-fluid">
    <div class="container min-height mt-5">
      <div class="row espace-prof top-forme justify-content-center">
        <div class="col-md-10 bg-white  shadow">
          <RubriquePro :niveau2="1" :niveau3="1" :niveau4="1" :level="true" />
          <div class="row p-5 justify-content-center dir-rtl">
            <div class="col-md-12 mt-3">
              <div class="row images-view" v-if="newImages.length">
                <!-- Nouveaux Images --->
                <div class="col-md-3 py-1" v-for="(image, key) in newImages" :key="key">
                  <div class="img text-center pl-2 pr-2">
                    <button type="button" class="btn btn-danger rounded-pill btn-delete-image"
                      @click="deleteNouveauImage(key)"><i class="fa fa-times"></i></button>
                    <img class="preview" style="max-height:150px;max-width:100%;" v-bind:ref="'image' + parseInt(key)" />
                  </div>
                </div>
                <!-- Currents Images ---->
                <hr />
              </div>
              <div class="row images-view" v-if="listImages.length">
                <div class="col-md-3 py-2" v-for="(image, key) in listImages" :key="key">
                  <div class="img text-center pl-2 pr-2">
                    <button type="button" class="btn btn-danger rounded-pill btn-delete-image"
                      @click="deleteImage(image)"><i class="fa fa-times"></i></button>
                    <img class="preview" v-if="image.image" style="max-height:150px;max-width:100%;"
                      v-bind:src="BaseUrl + '/storage/' + image.image" />
                    {{ image.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 justify-content-center mt-4">
              <div class="form-group text-center">
                <div class="image-upload">
                  <label for="images">
                    <img src="@/assets/images/icone-photos.png" />
                  </label>
                  <div :class="listImages.length > 0 || newImages.length > 0 ? 'img-btn-box-inline' : 'mt-4'">
                    <label style="margin-left:15px" class="btn btn-add-photos" for="images">{{
                      $t('forms.inscription.ajt_photos') }}</label>
                  </div>
                  <b-form-file id="images" v-model="images" ref="file" multiple @change="onFileChange" />
                </div>
                <span class="text-danger" v-if="errors.name">
                  {{ errors.name[0] }}
                </span>
              </div>
            </div>
            <!--
            <div class="col-md-12 mt-5 text-center">
              <div class="form-group-check" style="">
                 <div class="round">
                      <input type="checkbox" id="checkbox" />
                      <label id="lblcheckbox" for="checkbox"></label>
                </div>
                <label for="checkbox">J'accepte les régles conditions générales d'utilisation du site msafar.ma</label>
              </div>
            </div>
-->
            <div class="col-md-12 mt-5 text-center">
              <div class="form-group-check" style="">
                <div class="round">
                  <input type="checkbox" v-model="form.accepter_regles" id="checkbox" />
                  <label id="lblcheckbox" for="checkbox"></label>
                </div>
                <label for="checkbox">{{ $t('forms.inscription.regles') }}</label>
              </div>
            </div>
            <div class="col-md-12 mt-5 text-center connectez-vous">
              <b-button class="rounded-pill text-white btn-publier bg-msafar-primary" v-on:click="this.saveImages"  :disabled="loading">

                <span v-if="loading"><b-spinner  label="Busy"></b-spinner></span>
                Valider
              </b-button>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

//import http from "./../../http-common";

import User from "@/apis/User";
import Service from "@/apis/Services";
//import http from "./../../http-common";
import RubriquePro from "@/components/ProfilProfessionnel/RubriqueProProfil";
import Api from "@/apis/Api";
import http from "./../../http-common";

export default {
  name: "PhotosComponent",
  components: { RubriquePro },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      images: [],
      errors: [],
      listImages: [],
      newImages: [],
      attachments: [],
      loading: false,
      form: {
        accepter_regles: false
      },
      user: [],
      $path: "/images"
    };
  },
  computed: {
    getlenghtimage(){
      return this.newImages.length + this.listImages.length
    }
  },
  methods: {

    onFileChange(e) {

      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.newImages.push(selectedFiles[i]);
      }
      // this.images = this.listImages;
      this.readImages();
    },
    readImages() {
      for (let i = 0; i < this.newImages.length; i++) {
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener('load', function () {
          this.$refs['image' + parseInt(i)][0].src = reader.result;
        }.bind(this), false);  //add event listener

        reader.readAsDataURL(this.newImages[i]);
      }

    },
    saveImages() {
      this.loading = true;
      console.log(this.getlenghtimage)
      if(this.getlenghtimage > 8){
        this.$toaster.error("Le nombre maximum d'images est de 8")
        this.loading = false;
        return;
      }
      for (let i = 0; i < this.newImages.length; i++) {
        if (this.newImages[i].size > 2 * 1024 * 1024) {
          this.$toaster.error("Chaque image doit être inférieure à 2 Mo");
        this.loading = false;
          return;
        }
      }

      if (this.newImages.length > 0) {

        let formData = new FormData();
        for (var i = 0; i < this.newImages.length; i++) {
          let file = this.newImages[i];
          formData.append('images[' + i + ']', file);
        }
        this.path = "/images";
        if (this.user.type_service == 'hotel')
          formData.append("id_hotel", this.form.id);
        if (this.user.type_service == 'riad')
          formData.append("id_riad", this.form.id);
        if (this.user.type_service == 'maison-hote')
          formData.append("id_maison_hote", this.form.id);
        if (this.user.type_service == 'restaurant')
          formData.append("id_restaurant", this.form.id);
        if (this.user.type_service == 'appart-hotels')
          formData.append("id_apparthotels", this.form.id);


        if (this.user.type_service == 'guide') {
          formData.append("id_guide", this.form.id);
          this.path = "/post-images";
        }
        if (this.user.type_service == 'activite') {
          formData.append("id_activite", this.form.id);
          this.path = "/post-images";
        }


        Api().post(this.path, formData).then(response => {
          // this.$router.push("/professionnel/felicitation");

          console.log(response);
          this.$toaster.success('Votre Espace a bien été enregistré.')
          this.loading = false;
          // this.$router.push({ name: "InformationCompteProfil" });
          // this.$router.go();
          //setTimeout(function () { this.$router.push("/login"); }, 6000)
        })
          .catch(response => {
            //error
            console.log(response);
            //this.$toaster.error(response.data)
          });
      }
      else{
        this.loading = false;
      }

      this.validate();

    },
    getUser() {
      User.auth().then((response) => {
        //console.log(response.data);
        this.user = response.data;
        this.getHebergement();
      }).catch(error => {
        console.log(error);
      });
    },
    getHebergement() {
      console.log(this.user.id + ',' + this.user.type_service);
      Service.getHeberegement(this.user.id, this.user.type_service).then((response) => {
        //console.log(response.data);
        this.form = response.data;
        this.listImages = response.data.images;

        if (this.form.accepter_regles === 0)
          this.form.accepter_regles = false;
        else
          this.form.accepter_regles = true;
        //console.log(this.checked);
      }).catch(error => {
        console.log(error);
      });

    },
    deleteImage(image) {
      var path = '/images'
      if (this.user.type_service == 'guide' || this.user.type_service == 'activite') {
        path = "/post-images";
      }

      http.delete(`${path}/${image.id}`)
        .then(() => {
          this.listImages = this.listImages.filter(obj => obj.id != image.id);
          this.$toaster.success('image a bien été supprimé');
        })
        .catch(e => {
          console.log(e.data);
        })
    },
    deleteNouveauImage(index) {
      this.newImages.splice(index, 1);
      this.$refs.file.files.splice(index, 1);
      this.readImages();
    },
    validate() {
      Service.updateHebergement(this.form.id, this.user.type_service, this.form).then((response) => {
        this.form = response.data;
      }).catch(error => {
        console.log(error);
      });
    },

  }
  ,

  created() {
    this.getUser();
    // window.scrollTo(0, 350);
    console.log(this.$hotel.value);

  },
}
</script>
<style scoped>
.rtl label {
  direction: none;
  float: none;
}

legend {
  display: none !important;
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: 'Parcourir' !important;
}

.custom-file-input~.custom-file-label {
  border: 2px solid #008ec6;
}

.form-group-check label {
  font-size: 18px;
}

.form-group-check {
  width: 550px;
  margin: auto;
}

/* style */

.round {
  position: relative;
  width: 29px;
  float: left;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #4caf50;
  border-top: none;
  border-right: none;
  content: "";
  height: 8px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-45deg);
  width: 22px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: white;
  border-color: #4caf50;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.image-upload img {
  border-radius: 100px;
  width: 150px;
  cursor: pointer;
}

.image-upload>.b-form-file {
  display: none;
}


.btn-delete-image {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 2px 7px 0px 7px;
  background-color: #f0f0f0;
  border-color: #ececec;
  font-size: 14px;
  color: #ccccccc2;
}

.images-view .img {

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: #ccccccc2;

}

.images-view .col-md-3 {
  padding-right: 4px;
  padding-left: 4px;
}

.btn-add-photos,
.btn-add-photos:hover {
  color: #008bca;
  background-color: #fff;
  border-color: #008bca;
  border-radius: 50px;
  padding: 10px 40px;
  font-size: 22px;
}

.btn-add-photos,
.btn-add-photos:hover {
  color: #008bca;
  background-color: #fff;
  border-color: #008bca;
  border-radius: 50px;
  padding: 10px 40px;
  font-size: 18px;
}

.img-btn-box-inline {

  display: inline;
  margin-left: 20px;

}

.btn-publier {
  padding-left: 80px;
  padding-right: 80px;
  font-size: 22px;
}
.spinner-border{
  width: 20px !important;
  height: 20px !important;
}
</style>