<template>
    <div class="container">
        <div class="row mb-4">
          <div class="col-md-4 mb-4 col-xs-6">
            <router-link to="/hotels">
              <img src="@/assets/images/hotel.png" />
              <div>
                <h3 class="hotel">{{ $t("rubrique.hotels") }}</h3>
              </div>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 col-xs-6">
            <router-link to="/riads">
              <img src="@/assets/images/riads.png" />
              <div>
                <h3 class="riad">{{ $t("rubrique.riad_maisons") }}</h3>
              </div>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 col-xs-6">
            <router-link to="/apparthotels">
              <img src="@/assets/images/maison-d-hote.png" />
              <div>
                <h3 class="maison-hote">{{ $t("rubrique.apparthot") }}</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-6">
            <router-link to="/restaurants">
              <img src="@/assets/images/restaurants.png" />
              <div>
                <h3 class="restaurant">{{ $t("rubrique.restaurants") }}</h3>
              </div>
            </router-link>
          </div>
          <div class="col-md-4 col-xs-6">
            <router-link to="/activites-touristiques">
              <img src="@/assets/images/activites-touristiques.png" />
              <div>
                <h3 class="activite-touristique">
                  {{ $t("rubrique.activite_touristique") }}
                </h3>
              </div>
            </router-link>
          </div>
          <div class="col-md-4 col-xs-6">
            <router-link to="/produit_artisanal">
              <img src="@/assets/images/produit artisanal.png" />
              <div>
                <h3 class="guide-touristique">
                  {{ $t("rubrique.guides_touristique") }}
                </h3>
              </div>
            </router-link>
          </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"RubriqueImageComponent"
}
</script>