<template>
    <div class="container-fluid ">
        <div class="row footer">
            <div class="col-md-12 ">
                <div class="text-center" style="padding-top: 228px;">
                    <div class="col-md-3 float-right " style="width: 185px;margin-right: 50px;">
                        <!-- <div>
                            <img class="card-img-top" src="@/assets/images/app-store.png" alt="Card image cap"
                                style="margin-bottom: 5px;"><br>
                            <img class="card-img-top" src="@/assets/images/play-store.png" alt="Card image cap">
                        </div> -->
                    </div>
                    <div class="col-md-3">
                        <div class="col-md-12 rs-icone text-center">
                            <a href="https://www.facebook.com/msafar.ma" target="_blank"> <img class=""
                                    src="@/assets/images/rs/icone-fb.png" /></a>
                            <a href="https://www.linkedin.com/company/msafar-ma" target="_blank"> <img class=""
                                    src="@/assets/images/rs/icone-in.png" /></a>
                            <a href="https://www.instagram.com/msafar.ma/" target="_blank"> <img class=""
                                    src="@/assets/images/rs/icone-insta.png" /></a>
                            <a href="https://www.tiktok.com/@msafar.ma?lang=fr" target="_blank"> <img class=""
                                    src="@/assets/images/rs/icone-tiktok.png" /></a>
                            <a href="https://www.youtube.com/channel/UCUjQzHQ9Q3lc7aLPOiVvKLw/featured" target="_blank"
                                ref="no"> <img class="" src="@/assets/images/rs/icone-youtube.png" /></a>
                        </div>

                    </div>
                    <div class="col-md-12 mt-5 pt-2 justify-content-center">
                        <img @click="scrollToTop" class="top-footer" src="@/assets/images/fleche.png" alt="Card image cap">
                        <h4>
                            <router-link to="/mentions-legales"
                                style="color:#c79719;">{{ $t('footer.mentions_legales') }}</router-link>
                            <br>
                            <router-link to="/contact" style="color:#c79719;">{{ $t('footer.contact') }}</router-link>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-2">
           <p style="color: #845e00;">Copyright© 2022 Design and Conception by <a style="color: #845e00;" href="https://www.directinvest.ma/" target="_blank">Directinvest</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyFooter',
    methods: {
        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style></style>