<template>
  <div class="container">
    <div class="row justify-content-center">
      <b-alert v-if="error" variant="danger" show >
      {{ error }}
    </b-alert>
    </div>
    <div class="row mt-4 justify-content-center">
      <div class="col-md-6 mb-3">
        <b-form-input
          id="input-mote"
          :state="moteError ? false : null"
          :placeholder="$t('recherche.trouvez')"
          v-on:keyup.enter="search(mote)"
          v-model="mote"
        ></b-form-input>
      </div>
      <div class="col-md-2 mb-3">
        <select class="ville form-control"  v-model="ville">
        <option value="" readonly>
          {{ $t("recherche.villes") }}
        </option>
        <option
          v-for="ville of villes"
          :key="ville.id"
          :value="ville.id"
        >
          {{
            $i18n.locale === "العربية" ? ville.ville_ar : ville.ville
          }}
        </option>
      </select>
      </div>
      <div class="col-md-3 mb-3">
        <!-- <button class="btn btn-block btn-info rounded-pill"  v-on:click="search(mote)">
            <b-icon-search></b-icon-search> {{ $t('recherche.rechercher') }}
        </button> -->
        <button class="btn btn-block btn-info rounded-pill" v-on:click="search(mote)" :disabled="loading">
        <b-icon-search v-if="!loading"></b-icon-search>
        <span v-if="loading"><b-spinner  label="Busy"></b-spinner></span>
        {{ $t('recherche.rechercher') }}
       </button>
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-md-10">
        <Services :filteredItemsWithImages="filteredItemsWithImages"  :BaseUrl="BaseUrl" />
        <Suggestion v-if="suggestion" :hotels="SuggItems" />
      </div>
    </div>
    <div class="row rubriques">
      <Rubrique></Rubrique>
    </div>
  </div>
</template>

<script>
import Services from "@/components/Hebergements/services.vue";
import Rubrique from "@/components/Outils/RubriqueImage.vue";
import Suggestion from "@/components/Hebergements/CardComponents/Suggestion.vue";
import http from "@/http-common";
export default {
  metaInfo() {
      return {
        title: 'Msafar.ma - Profitez du tourisme au Maroc',
        meta: [
          {
            name: "description",
            content: "Avec Msafar.ma, sélectionnez les meilleurs hôtels, maison d'hôtes et riads et agrémentez vos voyages en choisissant vos activités touristiques.",
          },
        ],
      };
    },
  name: "ServicesComponent",
  components: { Rubrique,Services,Suggestion },
  data() {
    return {
      BaseUrl: process.env.VUE_APP_API_URL,
      lists:[],
      SuggItems:[],
      ville: "",
      villes: [],
      mote:null,
      moteError:null,
      loading: false,
      suggestion: false,
    }
  },
  mounted() {
    http.get("/carousel").then((response) => {
      this.SuggItems = response.data;
    });
    http
      .get("/villes")
      .then((response) => {
        this.villes = response.data;

      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  methods: {
  search() {
    this.categorieError = null;
    this.moteError = null;

      if ( !this.mote) {
        if (!this.mote) this.moteError = "Mote is required";
        return;
      }
      this.loading = true;

    http.get(`/search/${this.mote}?ville_id=${this.ville}`)
      .then((response) => {
        this.lists = response.data;
        if(this.filteredItemsWithImages.length==0){
          this.suggestion=true
        }else{
          this.suggestion=false
        }
        this.loading = false;
      })
      .catch(e => {
        this.errors.push(e)
        this.loading = false;
      });
      this.error = null;
  },

},
 computed:{
    filteredItemsWithImages() {
      return this.lists.filter(item => item.firstimage && item.firstimage.image);
    },
 }
};
</script>
<style scoped>
.spinner-border{
  width: 20px !important;
  height: 20px !important;
}
</style>