const Url = process.env.VUE_APP_API_URL;
const AppUrl = process.env.APP_URL;
export default
{
  metaInfo_fr(item,images) {
    return {
      title: item.meta_titre || (item.nom||item.nom_ar),
      meta: [
        {
        name: "description",
        content: item.meta_description || ((item.description ? item.description.substring(0, 155)+'...':'') || (item.description_ar ? item.description_ar.substring(0, 155)+'...':'')),
        },
        {
        property: "og:title",
        content: (item.nom||item.nom_ar),
        },
        {
          property: "og:description",
          content: ((item.description ? item.description.substring(0, 155)+'...':'') || (item.description_ar ? item.description_ar.substring(0, 155)+'...':'')),
        },
        {
          property: "og:image",
          content: Url + '/storage/' + images[0],
        },
        {
          property: 'og:url', content: AppUrl,
        },
        {
          property: 'og:type',
          content: 'article'
        },
        {
          property: 'article:published_time',
          content: item.created_at,
        },
        {
          property: 'article:modified_time',
          content: item.updated_at,
        },
        {
          script: [
            {
              type: 'application/ld+json',
              json: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Article',
                mainEntityOfPage: {
                  '@type': 'WebPage',
                  '@id': AppUrl,
                },
                headline: item.nom,
                description: item.description ?item.description.substring(0, 155)+'...':'',
                datePublished: item.created_at,
                dateModified: item.updated_at,
              }),
            },
          ],
        },
      ],
    };
  },
  metaInfo_ar(item,images) {
    return {
      title: item.meta_titre_ar || (item.nom_ar||item.nom),
      meta: [
        {
        name: "description",
        content: item.meta_description_ar || ((item.description_ar ? item.description_ar.substring(0, 155)+'...':'') || (item.description ? item.description.substring(0, 155)+'...':'')),
        },
        {
        property: "og:title",
        content: (item.nom_ar||item.nom),
        },
        {
          property: "og:description",
          content: ((item.description_ar ? item.description_ar.substring(0, 155)+'...':'') || (item.description ? item.description.substring(0, 155)+'...':'')),
        },
        {
          property: "og:image",
          content: Url + '/storage/' + images[0],
        },
        {
          property: 'og:url', content: AppUrl,
        },
        {
          property: 'og:type',
          content: 'article'
        },
        {
          property: 'article:published_time',
          content: item.created_at,
        },
        {
          property: 'article:modified_time',
          content: item.updated_at,
        },
        {
          script: [
            {
              type: 'application/ld+json',
              json: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Article',
                mainEntityOfPage: {
                  '@type': 'WebPage',
                  '@id': AppUrl,
                },
                headline: item.nom_ar,
                description: item.description_ar ?item.description.substring(0, 155)+'...':'',
                datePublished: item.created_at,
                dateModified: item.updated_at,
              }),
            },
          ],
        },
      ],
    };
  }
}