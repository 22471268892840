<template>
  <div class="container-fluid">
    <div class="container ">
      <div class="row">
        <div class="col-md-9 about" style="padding-top: 100px">
          <h1 class="text-center"><img src="@/assets/images/img-bladi.png" />{{ $t('navbar.qui_sommes_nous') }}</h1>
          <div class="bg-about mt-5">
            <div class="about-text dir-rtl">
              <div class="text-center" style="font-size: 16px;text-align:center!important">
                <p v-html="$t('about.voyages')"></p>
                <p v-html="$t('about.msafar_ma')"></p>
                <p v-html="$t('about.souhaitez')"></p>
                <p v-html="$t('about.maroc')"></p>
                <p v-html="$t('about.renocontre')"></p>
                <p v-html="$t('about.partage')"></p>
                <p v-html="$t('about.manque')"></p>
              </div>
            </div>
            <div class="about-text-mb">
              <img style="width: 100%!important;" src="@/assets/images/cadre-msafar.png" />
              <div class="text-center" style="font-size: 20px">
                <p v-html="$t('about.voyages')"></p>
                <p v-html="$t('about.msafar_ma')"></p>
                <p v-html="$t('about.souhaitez')"></p>
                <p v-html="$t('about.maroc')"></p>
                <p v-html="$t('about.renocontre')"></p>
                <p v-html="$t('about.partage')"></p>
                <p v-html="$t('about.manque')"></p>
              </div>
            </div>
          </div>
        </div>
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './Outils/Sidebar.vue';

export default {
  metaInfo() {
    const descriptionFr = "Avec Msafar.ma, découvrez le Voyage au Maroc autrement, en visitant les meilleurs endroits, en sélectionnant les meilleurs hôtels et en savourant la gastronomie marocaine.";
    const descriptionAr = "مع Msafar.ma، اكتشفوا السفر في المغرب بطريقة مختلفة عن طريق زيارة أفضل الأماكن، واختيار أفضل الفنادق، والاستمتاع بالمأكولات المغربية.";
    const titleFr = "Qui sommes-nous - organisez votre voyage au Maroc - Msafar.ma";
    const titleAr = "من نحن - تنظيم رحلتك في المغرب - Msafar.ma";
    const image = "@/assets/images/logo.png"; // Update with your actual image URL

    return {
      title: this.$i18n.locale === "Français" || this.$i18n.locale === "English" ? titleFr : titleAr,
      meta: [
        {
          name: "description",
          content: this.$i18n.locale === "Français" || this.$i18n.locale === "English" ? descriptionFr : descriptionAr,
        },
        {
          property: "og:title",
          content: this.$i18n.locale === "Français" || this.$i18n.locale === "English" ? titleFr : titleAr,
        },
        {
          property: "og:description",
          content: this.$i18n.locale === "Français" || this.$i18n.locale === "English" ? descriptionFr : descriptionAr,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: window.location.href,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: this.$i18n.locale === "Français" || this.$i18n.locale === "English" ? titleFr : titleAr,
        },
        {
          name: "twitter:description",
          content: this.$i18n.locale === "Français" || this.$i18n.locale === "English" ? descriptionFr : descriptionAr,
        },
        {
          name: "twitter:image",
          content: image,
        },
        {
          name: "robots",
          content: "index, follow",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
      ],
    };
  },

  name: "AboutComponent",
  components: { Sidebar }
}
</script>

<style scoped>
.rtl .about p{
  text-align: center;
}
@media screen and (min-width: 767px) {
  .about-text-mb{
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .about-text{
    display: none;
  }
  .bg-about{
    background: unset;
        height: unset;
        width: unset;
        margin: unset;
  }

  /* .about-text {
    margin-left: 60px;
    width: 299px;
  }

  .bg-about {
    background: url("@/assets/images/bg-about-mobile.png") no-repeat;
    background-size: 100% 100%;
    height: 1097px;
    width: 423px;
    margin: auto;

  } */
}
</style>