<template>


  <div class="container rubriques m-50">

    <div class="row">
      <div class="col-md-4 mb-4 col-12">
        <div>
          <router-link to="/hotels" ><h3 class="hotel ">{{$t('rubrique.hotels')}}</h3></router-link>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-12">
        <div>
          <router-link to="/riads" ><h3 class="riad">{{$t('rubrique.riad_maisons')}}</h3></router-link>
        </div>
      </div>
      <div class="col-md-4 mb-4 col-12">
        <div>
          <router-link to="/apparthotels" ><h3 class="maison-hote">{{$t('rubrique.apparthot')}}</h3></router-link>
        </div>
      </div>

      <div class="col-md-4 col-12">
        <div>
          <router-link to="/restaurants" ><h3 class="restaurant ">{{$t('rubrique.restaurants')}}</h3></router-link>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div>
          <router-link to="/activites-touristiques" ><h3 class="activite-touristique">{{$t('rubrique.activite_touristique')}}</h3></router-link>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div>
          <router-link to="/produit_artisanal" ><h3 class="guide-touristique">{{$t('rubrique.guides_touristique')}}</h3></router-link>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
export default {
  name: "RubriqueComponent"
}
</script>

<style scoped>

</style>